import { render, staticRenderFns } from "./SiteViewSectionList.vue?vue&type=template&id=d2712354&scoped=true&"
import script from "./SiteViewSectionList.vue?vue&type=script&lang=js&"
export * from "./SiteViewSectionList.vue?vue&type=script&lang=js&"
import style0 from "./SiteViewSectionList.vue?vue&type=style&index=0&id=d2712354&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2712354",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VChip,VIcon,VList,VListItem,VListItemContent,VListItemTitle})
